<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12" style="margin-bottom: 2em;">
        <div class="sub-title">Search:</div>
        <el-autocomplete
          id="ingredient_search_field"
          class="inline-input"
          v-model="form.formula_query"
          :fetch-suggestions="search_formulas"
          placeholder="Search..."
          :trigger-on-focus="false"
          no-value-key="name"
          popper-class="autocomplete-suggestion-popper-use-table"
          @select="select_product"
          clearable
          autocapitalize="none"
          style="width: 40em;"
        >
          <template slot="prepend">
            <i class="fal fa-search"></i>
          </template>

          <template slot-scope="{ item }">
            <div class="value">
              <span v-if="item.code">[{{ item.code }}] -</span>
              <span>{{ item.name || item.description }}</span>
              <span
                v-if="item.data.manufacturer && item.data.manufacturer.name"
              >&nbsp;({{ item.data.manufacturer.name }})&nbsp;</span>
            </div>
          </template>
          <!-- <template slot="append">
            <i @click="select_product" class="fal fa-plus-circle"></i>
          </template>-->
        </el-autocomplete>&nbsp;
      </el-col>
      <el-col :span="4" >
        <el-button v-show="results.length" type="text" @click="download_results">
          <i class="fad fa-file-download"></i> Download Results
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <section class="results_area" v-if="results">
          <el-table
            ref="singleTable"
            :data="results"
            highlight-current-row
            @current-change="handleSearchResultSelection"
            style="width: 100%"
            class="break-word"
          >
            <!-- <el-table-column type="index" width="50"></el-table-column> -->
            <el-table-column
              class-name="clickable avoid_break"
              property="description"
              label="Product"
              width="250"
              sortable
            >
              <template #default="scope">
                <span :class="scope.row.description.length > 30 ? 'longer' : ''">
                  <router-link :to="{
                    name: 'ingredient-inventory-item',
                    params: { identifier: scope.row.identifier }
                    }"
                    style="color: var(--el-table-text-color)"
                  >
                    {{scope.row.description}}
                  </router-link>
                </span>
              </template>
            </el-table-column>
            <el-table-column property="code" label="Code">
              <template #default="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{scope.row.code}}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column property="lot_number" label="Lot #" >
              <template #default="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{scope.row.lot_number}}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              property="beyond_use_date"
              label="Beyond Use"
              min-width="100"
              class="break-word"
              sortable
            >
              <template #default="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{moment_utc(scope.row.beyond_use_date)}}
                </router-link>
              </template>
            </el-table-column>
             <el-table-column
                label="Entered Inventory"
                width="180"
                property="date_created"
                sortable>
                <template slot-scope="scope">
                  <router-link :to="{
                    name: 'ingredient-inventory-item',
                    params: { identifier: scope.row.identifier }
                    }"
                    style="color: var(--el-table-text-color)"
                  >
                    <span v-if="scope.row.date_compounded">{{format_yyyy_mm_dd_as_method(scope.row.date_compounded) }}</span>
                    <span v-else>{{format_yyyy_mm_dd_as_method(scope.row.date_created) }}</span>
                  </router-link>
                </template>
              </el-table-column>
            <el-table-column property="data.inventory.quantity" label="QTY" sortable>
              <template slot-scope="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{scope.row.data.inventory.quantity}}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column property="current_inventory.quantity_remaining" label="Remaining" sortable>
              <template slot-scope="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{parseFloat(scope.row.current_inventory.quantity_remaining.toFixed(decimal_places))}}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column property="data.inventory.containers" label="Containers" sortable>
              <template slot-scope="scope">
                <router-link :to="{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }"
                  style="color: var(--el-table-text-color)"
                >
                  {{scope.row.data.inventory.containers}}
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");
import _ from 'lodash'

import moment from 'moment'

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  props: {
    search_removed: Boolean,
    search_ingredient_type: String,
    date_range: Array,
  },
  data() {
    return {
      categories: this.$store.state.categories,
      form: {
        formula_query: '',
        search_all_inventory: 0,
        search_removed_inventory: this.search_removed
      },
      results: []
    };
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    }
  },
  watch: {
    search_removed(val) {
      this.form.search_removed_inventory = val
      this.search_formulas(this.form.formula_query, ()=> {})
    },
    search_ingredient_type() {
      this.search_formulas(this.form.formula_query, ()=> {})
    },
    date_range() {
      this.search_formulas(this.form.formula_query, ()=> {})
    }
  },
  mounted() {
    document.querySelector('#ingredient_search_field').focus()
    if (this.$route.query && this.$route.query.q) {
      this.form.formula_query = this.$route.query.q
      this.search_formulas(this.form.formula_query, ()=> {})
    }
  },
  methods: {
    moment_utc(date) {
      if (date) {
        return moment.utc(date).local().format('YYYY-MM-DD')
      }
      return ''
    },
    reset() {
      this.form = {};
    },
    handleSearchResultSelection(row) {
      return //disable for router-link change
      this.$router.push({
        name: "ingredient-inventory-item",
        params: { identifier: row.identifier }
      });
    },
    select_product(selected_product) {
      this.form.selected_product = selected_product;
      this.product = {
        ...this.createFreshInventoryObject(),
        ...{
          product_identifier: selected_product.product_identifier,
          manufacturer_identifier: selected_product.manufacturer.identifier,
          ingredient_identifier: selected_product.identifier,
          ingredient: {
            description: selected_product.description
          },
          manufacturer: selected_product.manufacturer,

          description: selected_product.description,
          date_created: "",
          inventory: {}
        }
      };
      this.form = this.createFreshFormObject();
    },
    download_results() {
      const omit = (originalObject = {}, keysToOmit = []) => {
          const clonedObject = _.cloneDeep(originalObject);
          for (const path of keysToOmit) {
            for (const record of clonedObject) {
              _.unset(record, path)
            }
          }
          return clonedObject;
      }
      let csvContent = "data:text/csv;charset=utf-8,";
      let data = _.cloneDeep(this.results)
      data.forEach(item => {
        item['quantity_remaining'] = item.current_inventory && item.current_inventory.quantity_remaining
        item['quantity_used'] = item.current_inventory && item.current_inventory.quantity_used
        item['containers'] = item.data && item.data.inventory.containers
        item['quantity_initial'] = item.data && item.data.inventory.quantity
      })
      data = omit(data, ['data', 'current_inventory', 'realm'])
      const rows = []
      let column_list = [
        'id',
        'identifier',
        'ingredient_identifier',
        'lot_number',
        'lot_type',
        'product_identifier',
        'code',
        'beyond_use_date',
        'quantity_remaining',
        'quantity_used',
        'quantity_initial',
        'containers',
        'date_compounded',
        'date_entered_inventory',
        'description'
      ]
      data.forEach(item => {
        const row = []
        column_list.forEach(col => row.push(item[col]))
        rows.push(row)
      })
      column_list[1] = 'lot_identifier'
      csvContent += column_list.join(',') + '\n'
      csvContent += rows.map(item => item.join(',')).join('\n')
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "inventory_results.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    search_formulas(queryString, cb) {
      this.$router.replace({ name: 'ingredient-inventory-search', query: { q: queryString }})
      let self = this;
      // here we need to query the formula list
      let arg_string = queryString;
      console.log("arg_string", arg_string);
      let default_fields = ['date_created',
                            'date_updated',
                            'id',
                            'identifier',
                            'ingredient_identifier',
                            'lot_number',
                            'lot_type',
                            'product_identifier',
                            'realm',
                            'data.inventory'
                            ]
      let query_object = {
        explicit: 0,
        method: "LIKE",
        product_identifier: arg_string,
        description: arg_string,
        lot_number: null,
        search_all_inventory: this.form.search_all_inventory,
        search_removed_inventory: this.form.search_removed_inventory ? 1 : 0,
        date_start: this.date_range[0] || '',
        date_end: this.date_range[1] || ''
      };

      if (this.search_ingredient_type !== 'both') {
        query_object.lot_type = this.search_ingredient_type
      }
      // TODO:
      // here we should parse the arg_string and see if it matches a known-lot_number pattern
      // until then we will simply add it:
      if (queryString.match(/\d/) ? 1 : 0 ) {
        query_object.lot_number = arg_string
        // Object.defineProperty(query_object, 'lot_number', {
        //   value: arg_string,
        //   writable: true
        // });
      }
      let is_numeric = queryString.match(/^\d+$/)

      if (
        is_numeric
          ? 1
          : 0 || queryString.length == 36 || queryString.length == 22
      ) {
        query_object.explicit = 0;
        query_object.method = "=";
        // only lots have 22 char compressed barcodes at this time:
        if (queryString.length == 22) {
          query_object.lot_identifier = uuid_base64.decode(queryString);
            query_object.product_identifier = "";
            delete query_object.product_identifier;
        } else if ((queryString.length == 36
          && queryString.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
          ) {
            query_object.lot_identifier = arg_string
            query_object.product_identifier = "";
            delete query_object.product_identifier;
        } else {
          query_object.code = arg_string
        }

        query_object.description = "";
        delete query_object.description;

        if ( is_numeric &&
          (( queryString.length == 11 || queryString.length == 12 )
          // || (queryString.length > 8 && queryString.length < 13)
          )
        ) {
            query_object.ndc = queryString
            query_object.product_identifier = "";
            delete query_object.product_identifier;
          }
      }
        query_object.fields = default_fields
        console.log("query_object", [queryString, query_object]);
      NProgress.start();
      EventService.searchInventory(query_object)
        .then(results => {
          self.results = results.data.data;
          cb(self.results)
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },

    format_yyyy_mm_dd(row, col, val, indx) {
      return moment(val).format("YYYY-MM-DD");
    },
    format_yyyy_mm_dd_as_method(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.avoid_break .cell {
    word-break: break-word;
}
.cell .longer {
  font-size: 0.9em;
}
</style>
