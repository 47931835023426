var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{staticStyle:{"margin-bottom":"2em"},attrs:{"span":12}},[_c('div',{staticClass:"sub-title"},[_vm._v("Search:")]),_c('el-autocomplete',{staticClass:"inline-input",staticStyle:{"width":"40em"},attrs:{"id":"ingredient_search_field","fetch-suggestions":_vm.search_formulas,"placeholder":"Search...","trigger-on-focus":false,"no-value-key":"name","popper-class":"autocomplete-suggestion-popper-use-table","clearable":"","autocapitalize":"none"},on:{"select":_vm.select_product},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"value"},[(item.code)?_c('span',[_vm._v("["+_vm._s(item.code)+"] -")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name || item.description))]),(item.data.manufacturer && item.data.manufacturer.name)?_c('span',[_vm._v(" ("+_vm._s(item.data.manufacturer.name)+") ")]):_vm._e()])]}}]),model:{value:(_vm.form.formula_query),callback:function ($$v) {_vm.$set(_vm.form, "formula_query", $$v)},expression:"form.formula_query"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"fal fa-search"})])],2),_vm._v("  ")],1),_c('el-col',{attrs:{"span":4}},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.length),expression:"results.length"}],attrs:{"type":"text"},on:{"click":_vm.download_results}},[_c('i',{staticClass:"fad fa-file-download"}),_vm._v(" Download Results ")])],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":24}},[(_vm.results)?_c('section',{staticClass:"results_area"},[_c('el-table',{ref:"singleTable",staticClass:"break-word",staticStyle:{"width":"100%"},attrs:{"data":_vm.results,"highlight-current-row":""},on:{"current-change":_vm.handleSearchResultSelection}},[_c('el-table-column',{attrs:{"class-name":"clickable avoid_break","property":"description","label":"Product","width":"250","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.description.length > 30 ? 'longer' : ''},[_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }}},[_vm._v(" "+_vm._s(scope.row.description)+" ")])],1)]}}],null,false,968327663)}),_c('el-table-column',{attrs:{"property":"code","label":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(scope.row.code)+" ")])]}}],null,false,899886669)}),_c('el-table-column',{attrs:{"property":"lot_number","label":"Lot #"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(scope.row.lot_number)+" ")])]}}],null,false,420829963)}),_c('el-table-column',{staticClass:"break-word",attrs:{"property":"beyond_use_date","label":"Beyond Use","min-width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(_vm.moment_utc(scope.row.beyond_use_date))+" ")])]}}],null,false,40204320)}),_c('el-table-column',{attrs:{"label":"Entered Inventory","width":"180","property":"date_created","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                  name: 'ingredient-inventory-item',
                  params: { identifier: scope.row.identifier }
                  }}},[(scope.row.date_compounded)?_c('span',[_vm._v(_vm._s(_vm.format_yyyy_mm_dd_as_method(scope.row.date_compounded)))]):_c('span',[_vm._v(_vm._s(_vm.format_yyyy_mm_dd_as_method(scope.row.date_created)))])])]}}],null,false,1393332650)}),_c('el-table-column',{attrs:{"property":"data.inventory.quantity","label":"QTY","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(scope.row.data.inventory.quantity)+" ")])]}}],null,false,2033642977)}),_c('el-table-column',{attrs:{"property":"current_inventory.quantity_remaining","label":"Remaining","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(parseFloat(scope.row.current_inventory.quantity_remaining.toFixed(_vm.decimal_places)))+" ")])]}}],null,false,643086992)}),_c('el-table-column',{attrs:{"property":"data.inventory.containers","label":"Containers","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticStyle:{"color":"var(--el-table-text-color)"},attrs:{"to":{
                name: 'ingredient-inventory-item',
                params: { identifier: scope.row.identifier }
                }}},[_vm._v(" "+_vm._s(scope.row.data.inventory.containers)+" ")])]}}],null,false,1780956782)})],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }